<template>
  <vue3-progress />
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  components: {},
  methods: {
    handleVisibilityChange() {
      if (document.hidden) {
        document.title = 'Come back!';
      } else {
        document.title = this.$route.meta.title + ' - ' + 'InterRail'
      }
    }
  },
  mounted() {
    this.$progress.finish();
  },
  created() {
    this.$progress.start();

    this.$router.beforeEach((to, from, next) => {
      this.$progress.start();
      next();
    });

    this.$router.afterEach(() => {
      this.$progress.finish();
    });
  },
};

</script>
<style>
::selection {
  background-color: rgb(23, 162, 184);
  color: white;
}
</style>
